import React from 'react';
import styles from './style.module.scss';
import Image from 'next/image';
import Link from 'next/link';

interface BuyOnPhoneProps {
  // data: React.ReactNode[];
}

const BuyOnPhone: React.FC<BuyOnPhoneProps> = () => {
  return (
    <>
      <Link href="tel:9314444747" className={styles['buy-on-phone']}>
        <Image
          src="/images/buy-on-phone.png"
          quality={100}
          height={80}
          width={164}
          alt="furniture"
          className={styles['buy-phone-img']}
        />
      </Link>
    </>
  );
};

export default BuyOnPhone;
